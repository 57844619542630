.App, body {
  background-color: #414141;
}

#canvas {
  width: calc(100vw - 200px);
  height: calc(100vh - 40px);
  margin: 0 auto;
  position: relative;
  overflow: auto;
}

.no-editing ellipse.draw2d_HybridPort {
  visibility: hidden;
}

path.add-stroke-path {
  stroke: white;
  stroke-width: 1px;
}

svg .draw2d_Connection {
  stroke-linecap: butt;
  stroke-width: 10;
  stroke: #c5e2ff;
}

.navbar {
  width: 1000px;
  margin: 0 auto;
}

.cmb-tooltip {
  position: absolute;
}

.tooltip-content {
  display: flex;
}

.tooltip-content .man {
  display: flex;
  flex-direction: column;
  margin-left: 3px;
  margin-right: 3px;
  align-items: center;
}
.tooltip-content .man:hover {
  color: #fad600;
}

.tooltip-content .man .id {
  font-size: 9px;
  font-weight: bold;
}

.tooltip-content .man .anticon {
  font-size: 23px;
}

.man-list {
  color: white;
  background: rgb(0,0,0);
  background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(170,170,170,1) 100%);
  border: 2px solid white;
  border-radius: 4px;
  padding: 5px;
}

.ant-popover-inner-content {
  padding: 5px;
  background-color: #5b5b5b;
  border: 2px solid white;
  border-radius: 4px;
  color: white;
  line-height: 1;
}

.man-info {
  display: flex;
  align-items: center;
}

.man-info>.info {
  margin: 10px;
}

.man-info>.info .name {
  font-size: 18px;
  font-weight: bold;
}
.man-info>.info .sub {
  font-size: 10px;
  font-weight: bold;
}

.man-info>.last-seen {
  border-left: 1px solid white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 10px;
}

.last-seen .title {
  font-size: 10px;
}
.last-seen .number, .minute {
  font-size: 15px;
}